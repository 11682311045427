import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableContextProvider, useTableControls } from 'common/components/table/table-context';
import { Col, Row } from 'antd';
import TableBase from 'common/components/table/table-base';
import { ReactComponent as FilterIcon } from 'assets/icons/filtericon.svg';
import { SearchFilter } from '../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../common/enums/filter-types.enum';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';

import { ITableControls } from '../../../../common/components/table/table.types';
import { ColumnFilter } from '../../../../common/components/table/filters/column-filter/column-filter';
import addressesService from '../../../../common/services/addresses-service/addresses.service';
import { eiqSecureVacantAddressesInitialColumns } from '../../eiq-secure.config';
import eiqSecureStore from '../../stores/eiq-secure.store';
import { IAddress } from '../../../../common/services/street-service/street.service.types';
import { EIQ_SECURE_VACANT_ADDRESS_SEARCH_FILTER_ID } from 'common/helpers/search-filter.helper';

export const EiqSecureVacantAddressesList = observer(() => {
  const { isMobileOrTable, isDesktop } = useMedia();

  const tableControls = useTableControls({
    onSelectedRow: (selectedRow: IAddress) => {
      eiqSecureStore.setSelectedVacantAddress(selectedRow);
    },
    onFetch: addressesService.getVacantAddresses,
    initialColumns: eiqSecureVacantAddressesInitialColumns,
  });

  const resetHandler = (tableContext: ITableControls<any>) => {
    tableContext.resetFilter('AND');
  };

  const filters = (
    <ColumnFilter
      classNameContent="so-column-filter"
      width={258}
      buttonType="ghost"
      buttonIcon={<FilterIcon />}
      buttonText={''}
      onResetFilters={resetHandler}
      onBeforeApply={resetHandler}
      filterConfig={[
        {
          name: 'blockNumber',
          filterType: FilterTypesEnum.Equals,
          label: 'Block',
          type: 'INPUT',
        },
        {
          name: 'buildingNumber',
          filterType: FilterTypesEnum.Equals,
          label: 'Building',
          type: 'INPUT',
        },
        {
          name: 'street_community_name',
          filterType: FilterTypesEnum.StartWith,
          label: 'Community',
          type: 'INPUT',
        },
      ]}
    />
  );

  return (
    <>
      {(isDesktop || (isMobileOrTable && eiqSecureStore.admitFlow === null)) && (
        <TableContextProvider controls={tableControls}>
          {(isDesktop || isMobileOrTable) && (
            <>
              <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
                <Col flex="auto">
                  <SearchFilter
                    id={EIQ_SECURE_VACANT_ADDRESS_SEARCH_FILTER_ID}
                    className="full-width"
                    enableProgressiveSearch={true}
                    placeholder="Search by address, community"
                    suffix={filters}
                    rulesForColumn={{
                      name: FilterTypesEnum.Contains,
                      street_name: FilterTypesEnum.Contains,
                      streetNumber: FilterTypesEnum.Contains,
                      buildingNumber: FilterTypesEnum.Equals,
                      blockNumber: FilterTypesEnum.Equals,
                      lotNumber: FilterTypesEnum.Equals,
                      unit: FilterTypesEnum.Equals,
                      street_community_name: FilterTypesEnum.Contains,
                    }}
                  />
                </Col>
              </Row>
              <TableBase selectFirstRowByDefault />
            </>
          )}
        </TableContextProvider>
      )}
    </>
  );
});
