import { observer } from 'mobx-react-lite';
import { Card, Col, Row } from 'antd';
import React, { useMemo } from 'react';
import configurationStore from 'common/stores/configuration/configuration.store';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import TableBase from 'common/components/table/table-base';
import { cardClassesTableInitialColumns, facilityCodesTableInitialColumns } from '../community-information.config';
import '../community-information.scss';

export const CardAccessConfiguration = observer(() => {
  const cardClassesData = useMemo(() => {
    return configurationStore.configuration?.cardClasses.map((i) => ({ ...i, id: i.name }));
  }, [configurationStore.configuration]);

  const facilityCodesData = useMemo(() => {
    return configurationStore.configuration?.facilityCodes.map((i) => ({ ...i, id: i.value }));
  }, [configurationStore.configuration]);

  const cardClassesTableControls = useTableControls({
    data: cardClassesData,
    initialColumns: cardClassesTableInitialColumns,
    clientSide: true,
  });

  const facilityCodesTableControls = useTableControls({
    data: facilityCodesData,
    initialColumns: facilityCodesTableInitialColumns,
    clientSide: true,
  });

  return (
    <Card className="eiq-card eiq-card-border eiq-configuration">
      <Row className="eiq-card-header">
        <Col span={24}>
          <h1 className="h1">Card Access</h1>
        </Col>
      </Row>
      <Row>
        <div className="community-information__row-info">
          Server IP: {configurationStore.configuration?.cardAccessConfiguration?.serverIP}
        </div>
      </Row>
      <Row>
        <div className="community-information__row-info">
          Server Name: {configurationStore.configuration?.cardAccessConfiguration?.serverName}
        </div>
      </Row>
      <Row>
        <div className="community-information__row-info">
          Group Number: {configurationStore.configuration?.cardAccessConfiguration?.groupNumber}
        </div>
      </Row>
      <TableContextProvider controls={facilityCodesTableControls}>
        <h3>Facilities</h3>
        <TableBase />
      </TableContextProvider>
      <TableContextProvider controls={cardClassesTableControls}>
        <h3>Card Classes</h3>
        <TableBase />
      </TableContextProvider>
    </Card>
  );
});
