import { Button, Checkbox, Dropdown, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormWrapper } from '../../../form-wrapper/form-wrapper';
import { ReactComponent as ColumnIcon } from 'assets/icons/columns.svg';
import { useTableContext } from '../../table-context';
import { ColumnVisibilityProps } from '../../table.types';
import './column-visibility.scss';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { StrictModeDroppable } from 'common/components/droppable/strict-mode-droppable';

export const ColumnVisibility = ({
  contentClass,
  isColumnReorderAllow = false,
}: {
  contentClass?: string;
  isColumnReorderAllow?: boolean;
}) => {
  const tableContext = useTableContext();
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    setItems(tableContext.columns ?? []);
  }, [tableContext.columns]);

  const onFinish = (value: ColumnVisibilityProps) => {
    if (!value) {
      return;
    }

    const newColumns = items.map((column: any) => {
      for (const [key, isVisible] of Object.entries(value)) {
        if (column.key === key) {
          return { ...column, hidden: !isVisible };
        }
      }
      return column;
    });
    tableContext.updateColumns(newColumns);
    setOpen(false);
    console.log(value);
  };

  const reorder = (list: any, startIndex: any, endIndex: any): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder = reorder(items, result.source.index, result.destination.index);
    setItems(newOrder);
  };

  const content = (
    <div className={`column-visibility ${contentClass ?? ''}`}>
      <FormWrapper onFinish={onFinish} form={form}>
        <div className="column-visibility__header">
          <span>Columns</span>
          <Button htmlType="submit" type="link" size={'small'}>
            Apply
          </Button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items &&
                  items.map((column, index) => (
                    <Draggable key={column.key!} draggableId={column.key!} index={index} isDragDisabled={!isColumnReorderAllow}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="column-visibility-row">
                          <Form.Item key={column.key} name={column.key} valuePropName="checked" initialValue={!column.hidden}>
                            <Checkbox>{column.title}</Checkbox>
                          </Form.Item>
                          {isColumnReorderAllow && <DragIcon />}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </FormWrapper>
    </div>
  );

  return (
    <Dropdown open={open} onOpenChange={setOpen} dropdownRender={() => content} trigger={['click']}>
      <Button type="default" icon={<ColumnIcon />} size={'middle'} className="mobile-btn">
        Columns
      </Button>
    </Dropdown>
  );
};
