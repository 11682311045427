import { ArgsProps } from 'antd/es/notification/interface';
import { notification as antNotification, Space } from 'antd';
import * as React from 'react';

import { ReactComponent as VerticalLine } from 'assets/icons/vertical-line.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

class SHNotification {
  constructor() {}

  // Main props message and key
  error(config: ArgsProps) {
    antNotification.error({
      icon: (
        <Space>
          <VerticalLine />
          <ExclamationIcon />
        </Space>
      ),
      closeIcon: <CloseIcon />,
      placement: 'top',
      className: 'notification-wrapper',
      ...config,
    });
  }

  success(config: ArgsProps) {
    antNotification.success({
      icon: (
        <Space>
          <VerticalLine />
          <SuccessIcon />
        </Space>
      ),
      closeIcon: <CloseIcon />,
      placement: 'top',
      className: 'notification-wrapper',
      ...config,
    });
  }

  warning(config: ArgsProps) {
    antNotification.warning({
      icon: (
        <Space>
          <VerticalLine />
          <ExclamationIcon />
        </Space>
      ),
      closeIcon: <CloseIcon />,
      placement: 'top',
      className: 'notification-wrapper warning',
      ...config,
    });
  }

  destroy(key?: React.Key) {
    antNotification.destroy(key);
  }
}

export const notification = new SHNotification();
