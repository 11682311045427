import React, { forwardRef } from 'react';
import './citation-print.scss';
import { ICitationRead } from 'common/services/citation-service/citation.service.types';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import configurationStore from 'common/stores/configuration/configuration.store';
import { CitationStatus, citationStatusMap } from 'features/eiq-manage/enums/citation-status.enum';
import LogoIcon from 'assets/icons/eiq-logo-full.png';
import IntelliticketIcon from 'assets/icons/intelliticket.png';

interface ICitationPrint {
  citation: ICitationRead;
}

const CitationPrint = forwardRef(({ citation }: ICitationPrint, ref: any) => {
  const communityName = configurationStore.configuration?.default.communityName;
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

  const getCitationCountText = () => {
    if (citation.sequenceNumber === 1) {
      return 'first violation';
    }
    if (citation.sequenceNumber === 2) {
      return 'second violation';
    }
    if (citation.sequenceNumber === 3) {
      return 'third violation';
    }
    return `${citation.sequenceNumber}th violation`;
  };

  return (
    <div className="citation-print">
      <div className="citation-print-body" ref={ref}>
        <div className="header">
          <div className="title">{communityName} Community</div>
          <div className="title-info">
            <div className="ticket">Ticket#: {citation.externalId}</div>
            <div className="date">Date: {dayjs(citation.occurredAt).format('MM/DD/YYYY h:mmA')}</div>
          </div>
        </div>
        <div className="info">
          <div className="column">
            <div className="field">
              <span className="bold">Speed Violation: </span>
              <span className="orange bold">{citation.violatingSpeed}mph</span> at
              <span className="green bold"> {citation.speedLimit}mph</span> zone at
              <span className="bold italic"> {citation.locationName}</span>
            </div>
            <div className="field">
              <span className="bold">Payable Fine: </span>
              <span className="red bold">${citation.totalPaymentAmount} </span>
              {getCitationCountText()}
              {citation.status === CitationStatus.PayNow && (
                <span>
                  , due within 14 days
                  <span className="bold blue"> [{citationStatusMap[citation.status as CitationStatus]}]</span>
                </span>
              )}
            </div>
            <div className="field">
              <span className="bold">License Plate: </span>
              <span className="italic">
                {citation.licenseState} {citation.licensePlate}
              </span>
            </div>
            <div className="field">
              <span className="bold">Registered to: </span>
              <span className="italic">{citation.residentName}</span>
            </div>
          </div>
          <div className="column">
            A vehicle registered with <span className="italic">{communityName}</span> HOA, to the
            <span className="italic"> resident {citation.residentName}</span> with license plate number
            <span className="italic">{` ${citation.licenseState} ${citation.licensePlate} `}</span>
            has been identified as speeding <span className="orange">{citation.violatingSpeed} mph</span> or greater over the posted limit
            of
            <span className="green"> {citation.speedLimit} mph</span> on
            <span className="italic"> {dayjs(citation.occurredAt).format('MM/DD/YYYY h:mmA')}</span>.
          </div>
        </div>
        <div className="photos">
          <div className="header">Camera Image</div>
          <div className="images">
            {citation.photosUrl.map((item, index) => {
              return <img key={`citation-img-${index}`} src={item} alt=""></img>;
            })}
          </div>
          <div className="sub-header">
            <div>Location of the Violation</div>
            <div>Closeup of the License Plate</div>
          </div>
          <div className="license-images">
            <div>
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${citation.latitude},${citation.longitude}&zoom=16&size=400x400&markers=color:red|label:S|${citation.latitude},${citation.longitude}&key=${googleMapsApiKey}`}
                alt=""></img>
            </div>
            <div>
              <img src={citation.licensePlatePhotosUrl[0]} alt=""></img>
            </div>
          </div>
        </div>
        <div className="footer">
          <div>
            This is the <span className="bold">{getCitationCountText()}</span> for this resident and carries a fine of{' '}
            <span className="red">${citation.totalPaymentAmount}</span>
            {citation.status === CitationStatus.PayNow && <span> which is due within 14 days</span>}. You can pay online and securely by{' '}
            <a href={citation.paymentLinkUrl ?? '#'} className="blue">
              clicking here now
            </a>
            .
          </div>
          <div>If you have any objections to this fine, please contact your HOA with your ticket number {citation.externalId}.</div>
          <div>
            Issued by: <br />
            <span className="italic">{communityName} Community HOA</span>
          </div>
          <div>
            This citation is issued by the association board through the EIQ-IntelliTicket Enforcement System, resulting in a fine and/or
            transponder suspension as provided for by applicable state statutes.
          </div>
          <div className="logos">
            <img src={LogoIcon} alt="logo" />
            <img src={IntelliticketIcon} alt="intelliticket"></img>
          </div>
        </div>
      </div>
    </div>
  );
});

export default observer(CitationPrint);
