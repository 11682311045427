// Admittance
import { AdmittanceType } from '../enums/admittance-type.enum';
import { generateOption } from '../helpers/generate-option.helper';

export const admittanceTypeMap: Record<AdmittanceType, string> = {
  [AdmittanceType.Undefined]: 'Undefined',
  [AdmittanceType.Approved]: 'Approved',
  [AdmittanceType.TurnBack]: 'Turned back',
  [AdmittanceType.Readmission]: 'Readmission',
  [AdmittanceType.Credential]: 'Credential',
  [AdmittanceType.CredentialDenied]: 'Credential denied',
};
export const admittanceOptions = generateOption(AdmittanceType, admittanceTypeMap);
export const admittanceColorMap: Record<AdmittanceType, string> = {
  [AdmittanceType.Undefined]: '#CCCCCC',
  [AdmittanceType.Approved]: '#C1FFEA',
  [AdmittanceType.TurnBack]: '#E1E5EA',
  [AdmittanceType.Readmission]: '#E1E5EA',
  [AdmittanceType.Credential]: '#C1FFEA',
  [AdmittanceType.CredentialDenied]: '#E1E5EA',
};

export const admittanceWGColorMap: Record<AdmittanceType, string> = {
  [AdmittanceType.Undefined]: '#CCCCCC',
  [AdmittanceType.Approved]: '#505762',
  [AdmittanceType.TurnBack]: '#E86056',
  [AdmittanceType.Readmission]: '#E86056',
  [AdmittanceType.Credential]: '#505762',
  [AdmittanceType.CredentialDenied]: '#E86056',
};
