import { Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const { Sider, Content } = Layout;

export const CommunityInformation = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: 'EIQ-Voice', key: '/eiq-manage/community-information/eiq-voice' },
    { label: 'Card Access', key: '/eiq-manage/community-information/card-access' },
    { label: 'Gates', key: '/eiq-manage/community-information/gates' },
    { label: 'Verification', key: '/eiq-manage/community-information/verification ' },
  ];

  return (
    <Layout hasSider className="accounts-layout">
      <Sider className="sider-wrapper">
        <Menu
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[location.pathname]}
          items={tabs}
          className="sider-tabs"
          onClick={(tab) => navigate(tab.key)}
        />
      </Sider>
      <Content className="content-style">
        <Outlet />
      </Content>
    </Layout>
  );
});
