import { ServerClient } from 'common/utils/http/http-clients';
import { IUserPersonalizationRequest, IUserPersonalizationResponse } from './user-personalization.service.types';

class UserPersonalizationService {
  async get(): Promise<IUserPersonalizationResponse> {
    const res = await ServerClient.get<IUserPersonalizationResponse>(`/user-personalizations/me`);
    return res?.data;
  }

  async updateTableColumnsPersonalization(tablePersonalizationData: IUserPersonalizationRequest): Promise<IUserPersonalizationResponse> {
    const res = await ServerClient.put<IUserPersonalizationResponse>(`/user-personalizations/me`, tablePersonalizationData);
    return res?.data;
  }
}

export const personalizationService = new UserPersonalizationService();
export default personalizationService;
