import { Card, Col, Form, Input, Row, Space } from 'antd';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import configurationService from 'common/services/configuration-service/configuration.service';
import { ISystemConfigurationUpdate } from 'common/services/configuration-service/configuration.service.types';
import { notification } from 'common/utils/notification';
import configurationStore from 'common/stores/configuration/configuration.store';
import { useEffect } from 'react';

export const GuestLimitations = () => {
  const [form] = Form.useForm();
  const initData = configurationStore.configuration?.guestLimitations;

  useEffect(() => {
    if (form) {
      form.setFieldsValue(initData);
    }
  }, [form, initData]);

  let timeoutId: any;

  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      form.submit();
    }, 1500);
  };

  const onFinish = async (values: any) => {
    const model: ISystemConfigurationUpdate = {
      citations: null,
      serviceHours: null,
      default: null,
      guestLimitations: {
        permanentGuestsLimitation: Number(values.permanentGuestsLimitation),
        guestVisitDaysLimitation: Number(values.guestVisitDaysLimitation),
        maximumGuestValidityLimitation: Number(values.maximumGuestValidityLimitation),
      },
    };

    const result = await configurationService.updateSystemConfiguration(model);
    configurationStore.setConfiguration(result);
    notification.success({
      message: 'Configurations successfully updated',
      duration: 5,
    });
  };
  return (
    <FormWrapper form={form} onValuesChange={handleFormValuesChange} onFinish={onFinish} layout="vertical">
      <Card className="eiq-card eiq-card-border eiq-configuration">
        <Row className="eiq-card-header">
          <Col span={24}>
            <h1 className="h1">Guest Limitations</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <Space className="full-width">
              <Form.Item name="permanentGuestsLimitation" label="Number of permanent guests a resident can have">
                <Input type="number" min={0} />
              </Form.Item>
            </Space>
            <Space className="full-width" style={{ display: 'none' }}>
              <Form.Item name="guestVisitDaysLimitation" label="Limitations on the number of guest visits per year (days)">
                <Input type="number" min={0} max={365} />
              </Form.Item>
            </Space>
            <Space className="full-width" style={{ display: 'none' }}>
              <Form.Item name="maximumGuestValidityLimitation" label="Maximum days allowed for guest validity (days)">
                <Input type="number" min={0} max={365} />
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Card>
    </FormWrapper>
  );
};
