import { Button, Form, Input, notification } from 'antd';
import TextEditor from 'common/components/form-elements/editor/editor';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { useRef, useState } from 'react';
import Quill from 'quill';
import { EditorProps } from 'common/components/form-elements/editor/editor.types';
import notificationService from 'common/services/notification-service/notification-service';
import { INotificationMessage } from 'common/services/notification-service/notification-service.types';

export const EmailNotifications = () => {
  const [message, setMessage] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [textWithoutHtml, setTextWithoutHtml] = useState<string>('');
  const [isEmailButtonDisabled, setIsEmailButtonDisabled] = useState<boolean>(false);

  const quillRef = useRef<Quill | null>(null);

  const handleTextChange = (content: string) => {
    setMessage(content);
  };

  const handleQuillChange = () => {
    if (quillRef.current) {
      const content = quillRef.current.root.innerHTML;

      setTextWithoutHtml(quillRef.current.getText().trim());
      handleTextChange(content);
    }
  };

  const EmailEditorProps: EditorProps = {
    readOnly: false,
    onTextChange: handleQuillChange,
    maxLength: 1000,
    toolbarOptions: {
      enableBold: true,
      enableItalic: true,
      enableUnderline: true,
      enableLink: true,
      enableList: true,
      enableClean: true,
    },
  };

  const handleSendNotification = async () => {
    try {
      setIsEmailButtonDisabled(true);

      const messageWithTitle: INotificationMessage = {
        message: message,
        subject: subject,
      };
      await notificationService.sendEmailMassNotification(messageWithTitle);

      setIsEmailButtonDisabled(false);
      notification.success({
        message: 'Success',
        description: 'Email notification sent successfully!',
        duration: 1.5,
      });
    } catch (error: any) {
      const status = error.status;
      if (status === 422) {
        notification.error({
          message: 'Please remove formatting',
          duration: 1.5,
        });
      } else {
        console.error('Failed to send email notification:', error);
      }
      setIsEmailButtonDisabled(false);
    }
  };

  return (
    <FormWrapper layout="vertical">
      <Form.Item label="Email Subject">
        <Input showCount maxLength={50} name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
      </Form.Item>
      <Form.Item label="Email Message">
        <div>
          <TextEditor ref={quillRef} {...EmailEditorProps} />
        </div>
      </Form.Item>
      <Form.Item>
        <div className="btn-container">
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSendNotification}
            disabled={textWithoutHtml.length === 0 || subject.length === 0 || isEmailButtonDisabled}>
            Send Email
          </Button>
          <p>*Click only once</p>
        </div>
      </Form.Item>
    </FormWrapper>
  );
};
