import { observer } from 'mobx-react-lite';
import { Card, Col, Row } from 'antd';
import React, { useMemo } from 'react';
import configurationStore from 'common/stores/configuration/configuration.store';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { gatesTableInitialColumns } from '../community-information.config';
import TableBase from 'common/components/table/table-base';

export const GatesConfiguration = observer(() => {
  const data = useMemo(() => {
    return configurationStore.configuration?.gates.map((i) => ({ ...i, id: i.name }));
  }, [configurationStore.configuration]);

  const tableControls = useTableControls({
    data: data,
    initialColumns: gatesTableInitialColumns,
    clientSide: true,
  });

  return (
    <Card className="eiq-card eiq-card-border eiq-configuration">
      <Row className="eiq-card-header">
        <Col span={24}>
          <h1 className="h1">Gates</h1>
        </Col>
      </Row>
      <TableContextProvider controls={tableControls}>
        <TableBase />
      </TableContextProvider>
    </Card>
  );
});
