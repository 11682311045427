import './residents.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import TableBase from 'common/components/table/table-base';
import { ReactComponent as ImportIcon } from 'assets/icons/import.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/move.svg';
import ResidentAddEditModal from './components/resident-add-edit-modal/resident-add-edit-modal';
import TableContextProvider, { useTableControls } from '../../../../common/components/table/table-context';
import { residentFilterConfig, residentAddressInitialColumns } from './resident.config';
import InfoBlock from 'common/components/info-block/info-block';
import type { TabsProps } from 'antd';
import EiqTabs from 'common/components/tabs/eiq-tabs';
import EiqTabsTitleLeft from 'common/components/info-block/title-left/eiq-tabs-title-left';
import ResidentDeleteModal from './components/resident-delete-modal/resident-delete-modal';
import ResidentMoveModal from './components/resident-move-modal/resident-move-modal';
import { IResidentMoveModalProps } from './components/resident-move-modal/resident-move-modal.types';
import { SearchFilter } from '../../../../common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from '../../../../common/enums/filter-types.enum';
import { ColumnVisibility } from '../../../../common/components/table/filters/column-visibility/column-visibility';
import { ColumnFilter } from '../../../../common/components/table/filters/column-filter/column-filter';
import ResidentInfo from './components/info-block/resident-info/resident-info';
import { useModal } from '../../../../common/hooks/use-modal/use-modal.hook';
import residentsService from '../../../../common/services/residents-service/residents.service';
import {
  IResidentAddress,
  IResidentAddressRead,
  IResidentRead,
} from '../../../../common/services/residents-service/residents.service.types';
import { IResidentForm } from './residents.type';
import { observer } from 'mobx-react-lite';

import Notifications from './components/info-block/notifications/notifications';
import { Pets } from './components/info-block/pets/pets';
import residentStore from '../../stores/resident.store';
import { GuestList } from './components/info-block/guest-list/guest-list';
import { VehicleList } from './components/info-block/vehicles/vehicle-list';
import { Addresses } from './components/info-block/addresses/addresses';
import { Occupants } from './components/info-block/occupants/occupants';
import { StrToDate } from 'common/helpers/date.helper';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { notification } from 'common/utils/notification';
import featureStore from 'common/stores/features/feature.store';
import { AccessCardList } from './components/info-block/access-cards/access-card-list';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';
import {
  filterColumnsByPersonalization,
  getTablePersonalizationColumnsData,
  getTablePersonalizationSortData,
  personalizationDefaultTaleSort,
} from 'common/components/table/table-helpers';

const TABLE_NAME = 'residents';

export const Residents = observer(() => {
  const [isResidentCanBeMoved, setIsResidentCanBeMoved] = useState(false);
  // Table config
  const tableControls = useTableControls({
    onSelectedRow: (selectedRow: IResidentAddressRead) => {
      residentStore.setCurrentRow(selectedRow);
    },
    onFetch: residentsService.getResidentAddresses,
    initialColumns: filterColumnsByPersonalization(residentAddressInitialColumns, TABLE_NAME),
    defaultSort: personalizationDefaultTaleSort(TABLE_NAME),
    onUpdateColumns: async (columns: any[], sortBy: any) => {
      await userPersonalizationStore.updateTableColumnsPersonalization({
        tableName: TABLE_NAME,
        tableColumns: getTablePersonalizationColumnsData(columns),
        tableSort: getTablePersonalizationSortData(sortBy),
      });
    },
  });

  const addResidentModal = useModal({
    onClose: (isSuccessSaved) => {
      addResidentModal.setModalInitData(null);
      if (isSuccessSaved) {
        tableControls.refetchData();
      }
    },
  });

  const editResidentModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow() as IResidentAddressRead;

      if (!selectedRow) {
        return;
      }

      const residentFromData: IResidentForm = transformReadToForm(selectedRow);
      setModalInitData(residentFromData);
    },
    onClose: (isSuccessSaved, resident: IResidentRead) => {
      if (isSuccessSaved) {
        const currentRow = tableControls.getSelectedRow() as IResidentAddressRead;
        tableControls.updateSelectedRow({ ...currentRow, resident: { ...resident } });

        const details = residentStore.selectedResident;
        if (details) {
          //TODO:: update all props
          details.firstName = resident.firstName!;
          details.lastName = resident.lastName!;
          residentStore.setResident(details);
        }
      }
    },
  });

  const deleteResidentModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: IResidentAddressRead = tableControls.getSelectedRow();
      const initData: any = {
        name: `${selectedRow.resident.firstName} ${selectedRow.resident.lastName}`,
        addressId: selectedRow.address.id,
        residentId: selectedRow.resident.id,
        ownershipType: selectedRow.ownershipType,
      };
      setModalInitData(initData);
    },
    onClose: (isSuccessSaved, isAddNewOwner) => {
      if (isSuccessSaved) {
        if (isAddNewOwner) {
          addResidentModal.setModalInitData(residentStore.selectedResidentAddress);
          addResidentModal.showModal();
        }

        tableControls.refetchData();
        tableControls.setSelectedRow(null);
      }
    },
  });

  const moveResidentModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow() as IResidentAddressRead;
      if (!selectedRow) {
        return;
      }

      const initData: IResidentMoveModalProps = {
        addressOptions: residentStore.addresses ?? [],
        firstName: selectedRow.resident.firstName,
        lastName: selectedRow.resident.lastName,
        residentId: selectedRow.resident.id,
      };
      setModalInitData(initData);
    },
    onClose: (isSuccessSaved) => {
      if (isSuccessSaved) {
        tableControls.refetchData();
        tableControls.setSelectedRow(null);
      }
    },
  });

  const handleSendWelcomeEmail = (event: any) => {
    const selectedRow = tableControls.getSelectedRow() as IResidentAddressRead;

    if (!selectedRow) {
      return;
    }

    residentsService.sendWelcomeEmail(selectedRow.resident.id).then(() => {
      notification.success({ message: 'Welcome email has been successfully sent' });
    });
  };

  const handleRestoreEIQConnect = (event: any): any => {
    const selectedRow = tableControls.getSelectedRow() as IResidentAddressRead;

    if (!selectedRow) {
      return;
    }

    residentsService.recover(selectedRow.resident.id).then(() => {
      const details = residentStore.selectedResident;
      if (details) {
        details.isDeletionRequested = false;
        residentStore.setResident(details);
      }
      notification.success({ message: 'EIQ connect restored' });
    });
  };

  const residentActionsButtons = (
    <div className="action-buttons-container">
      <Button size="middle" onClick={handleRestoreEIQConnect} disabled={!residentStore.selectedResident?.isDeletionRequested}>
        Restore EIQ connect
      </Button>
      {featureStore.isConnectEnabled && (
        <Button size="middle" onClick={handleSendWelcomeEmail}>
          Send a welcome email
        </Button>
      )}
      {isResidentCanBeMoved && (
        <Button icon={<MoveIcon stroke="#505762" />} size="middle" onClick={moveResidentModal.showModal}>
          Move
        </Button>
      )}
      <Button icon={<DeleteIcon stroke="#505762" />} size="middle" onClick={deleteResidentModal.showModal}>
        Delete
      </Button>

      <Button icon={<EditIcon />} size="middle" onClick={editResidentModal.showModal}>
        Edit
      </Button>
    </div>
  );

  const setResidentAddress = (isTableControlsUpdate: boolean = true) => {
    const currentRow = tableControls.getSelectedRow() as IResidentAddressRead;

    const residentId = currentRow?.resident?.id;
    const addressId = currentRow?.address?.id;

    if (!residentId || !addressId) {
      residentStore.setResident(null);
      return;
    }

    residentsService
      .getResidentDetails(residentId)
      .then((data) => {
        if (isTableControlsUpdate) {
          tableControls.updateSelectedRow(data);
        }
        residentStore.setResident(data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    setResidentAddress(false);
  }, [tableControls.getSelectedRow()?.id, residentStore.selectedResidentAddress?.id]);

  useEffect(() => {
    const residentAddress = tableControls.getSelectedRow() as IResidentAddressRead;
    setIsResidentCanBeMoved(
      residentAddress?.ownershipType === ResidentTypesEnum.Owner || residentAddress?.ownershipType === ResidentTypesEnum.Renter,
    );
  }, [tableControls.getSelectedRow()?.id]);

  const items: TabsProps['items'] = [
    {
      key: 'Occupants',
      label: 'Occupants',
      children: <Occupants />,
    },
    {
      key: 'GuestList',
      label: 'Guest List',
      children: <GuestList />,
    },
    {
      key: 'Vehicles',
      label: 'Vehicles',
      children: <VehicleList />,
    },
  ];

  if (featureStore.isCardAccessEnabled) {
    items.push({
      key: 'Devices',
      label: 'Other Devices',
      children: <AccessCardList />,
    });
  }

  items.push(
    {
      key: 'Info',
      label: 'Info',
      children: <ResidentInfo actionBlock={residentActionsButtons} />,
    },
    {
      key: 'Addresses',
      label: 'Addresses',
      children: <Addresses />,
    },
    {
      key: 'Notifications',
      label: 'Notifications',
      children: <Notifications />,
    },
    {
      key: 'Pets',
      label: 'Pets',
      children: <Pets />,
    },
  );

  const residentName = useMemo(() => {
    const selectedRow = tableControls.getSelectedRow() as IResidentAddressRead;

    if (!selectedRow) {
      return '';
    }

    return `${selectedRow.resident.firstName} ${selectedRow.resident.lastName}`;
  }, [tableControls.getSelectedRow()]);

  const ownershipType = useMemo(() => {
    const selectedRow = tableControls.getSelectedRow() as IResidentAddressRead;
    return selectedRow?.ownershipType;
  }, [tableControls.getSelectedRow()]);

  const getTabs = useCallback(() => {
    if (ownershipType === ResidentTypesEnum.Developer) {
      // remove tab with key 'Pets' from items
      return items.filter((item) => item.key !== 'Pets');
    } else {
      return items;
    }
  }, [ownershipType, items]);

  return (
    <div className="resident-page">
      <TableContextProvider controls={tableControls}>
        <Card className="eiq-card eiq-card-border full-card">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">Residents</h1>
            </Col>
          </Row>

          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                adaptiveSearch={true}
                enableProgressiveSearch={true}
                placeholder="Search by first name, last name, address, phone, pin, club ID, email"
                rulesForColumn={{
                  name: FilterTypesEnum.StartWith,
                  address: FilterTypesEnum.StartWith,
                  address_blockNumber: FilterTypesEnum.StartWith,
                  address_lotNumber: FilterTypesEnum.StartWith,
                  residentPrimaryPhone: FilterTypesEnum.StartWith,
                  resident_pin: FilterTypesEnum.StartWith,
                  resident_clubID: FilterTypesEnum.StartWith,
                  resident_email: FilterTypesEnum.Contains,
                }}
                rightSide={
                  <Space size={8}>
                    <Button type="default" disabled={true} icon={<ImportIcon />} size={'middle'} className="mobile-btn">
                      Import
                    </Button>

                    <ColumnVisibility isColumnReorderAllow={true} />

                    <ColumnFilter filterConfig={residentFilterConfig} />

                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      size="middle"
                      onClick={addResidentModal.showModal}
                      className="mobile-btn">
                      Add
                    </Button>
                  </Space>
                }
              />
            </Col>
          </Row>
          <TableBase selectFirstRowByDefault />

          {addResidentModal.isOpenModal && (
            <ResidentAddEditModal
              title="Add"
              isEdit={false}
              isOpen={addResidentModal.isOpenModal}
              onClose={addResidentModal.hideModal}
              initData={addResidentModal?.initData}
            />
          )}
        </Card>

        <InfoBlock>
          <EiqTabs defaultActiveKey="Info" items={getTabs()} tabBarGutter={16} left={<EiqTabsTitleLeft title={residentName} />} />
        </InfoBlock>
      </TableContextProvider>
      {moveResidentModal.isOpenModal && (
        <ResidentMoveModal
          initData={moveResidentModal?.initData}
          title="Move"
          isOpen={moveResidentModal?.isOpenModal}
          onClose={moveResidentModal?.hideModal}
        />
      )}
      {editResidentModal && (
        <ResidentAddEditModal
          {...editResidentModal?.initData}
          title="Edit"
          isEdit={true}
          isOpen={editResidentModal?.isOpenModal}
          onClose={editResidentModal?.hideModal}
        />
      )}
      {deleteResidentModal?.isOpenModal && (
        <ResidentDeleteModal
          initData={deleteResidentModal?.initData}
          title="Delete"
          isOpen={deleteResidentModal?.isOpenModal}
          onClose={deleteResidentModal?.hideModal}
        />
      )}
    </div>
  );
});

export const transformReadToForm = (readData: IResidentAddressRead): any => {
  //[TODO] Remove this and use readData
  const { id, firstName, lastName, email, clubId, pin, alertMessage, vacationStartDate, vacationEndDate, phones, source } =
    readData.resident;

  //[TODO] Remove this block
  const address: IResidentAddress = {
    addressId: readData?.address.id,
    relatedResidentId: readData?.relatedTo?.residentId,
    ownershipType: readData?.ownershipType,
    rentStartDate: readData?.rentStartDate ? StrToDate(readData?.rentStartDate) : null,
    rentEndDate: readData?.rentEndDate ? StrToDate(readData?.rentEndDate) : null,
  };

  const form: IResidentForm = {
    id,
    firstName: firstName,
    lastName: lastName,
    email: email,
    pin: pin,
    clubId: clubId ? clubId.toString() : undefined,
    vacationPeriod: {
      startDate: vacationStartDate ? StrToDate(vacationStartDate) : null,
      endDate: vacationEndDate ? StrToDate(vacationEndDate) : null,
    },
    alertMessage: alertMessage,
    address: address,
    phones: JSON.parse(JSON.stringify(phones)),
    vacationEndDate: vacationEndDate,
    vacationStartDate: vacationStartDate,
  };

  return {
    extraData: {
      isVacation: vacationStartDate !== null && vacationEndDate !== null,
      source: source,
    },
    initState: form,
  };
};
