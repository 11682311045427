import { observer } from 'mobx-react-lite';
import { Card, Col, Row } from 'antd';
import React from 'react';
import configurationStore from 'common/stores/configuration/configuration.store';
import { formatPhone } from 'common/helpers/phone.helper';

export const EIQVoiceConfiguration = observer(() => {
  return (
    <Card className="eiq-card eiq-card-border eiq-configuration">
      <Row className="eiq-card-header">
        <Col span={24}>
          <h1 className="h1">EIQ-Voice</h1>
        </Col>
      </Row>
      <Row>EIQ-Voice phone number: {formatPhone(configurationStore.communitySettings?.eiqVoicePhoneNumber)}</Row>
    </Card>
  );
});
