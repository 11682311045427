import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Col, Divider, Row, Form } from 'antd';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import {
  IServiceHoursConfiguration,
  IServiceHoursConfigurationUpdate,
  ISystemConfigurationUpdate,
} from 'common/services/configuration-service/configuration.service.types';
import configurationService from 'common/services/configuration-service/configuration.service';
import configurationStore from 'common/stores/configuration/configuration.store';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { stringToTimeOnly, timeOnlyToString } from 'common/helpers/time-only.helper';
import { IServiceHoursConfigurationForm } from './service-hours-configuration.types';
import { ITimeOnly } from 'common/models/time-only.interface';
import InputTimePeriod from '../../components/input-time-period';

export const ServiceHoursConfiguration: FC = observer(() => {
  const { isMobile, isTablet } = useMedia();
  const [form] = Form.useForm();
  const initValues = mapToFormValues(configurationStore.configuration?.serviceHours);

  const getColSize = () => {
    if (isMobile) {
      return 24;
    }
    if (isTablet) {
      return 12;
    }
    return 8;
  };

  let timeoutId: any;

  const handleFormValuesChange = (changedValues: ITimeOnly | null, allValues: IServiceHoursConfigurationForm) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      form.submit();
    }, 1000);
  };

  const onFinish = async (values: IServiceHoursConfigurationForm) => {
    const serviceHours = mapToRequest(values);
    const data: ISystemConfigurationUpdate = {
      default: null,
      citations: null,
      serviceHours: serviceHours,
      guestLimitations: null,
    };

    const result = await configurationService.updateSystemConfiguration(data);
    configurationStore.setConfiguration(result);
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue(initValues);
    }
  }, [form, initValues]);

  return (
    <Card className="eiq-card eiq-card-border eiq-configuration">
      <Row className="eiq-card-header">
        <Col span={24}>
          <h1 className="h1">Service hours</h1>
        </Col>
      </Row>
      <FormWrapper form={form} onFinish={onFinish} onValuesChange={handleFormValuesChange} initialValues={initValues} layout="vertical">
        <Row>
          <Col span={getColSize()}>
            <InputTimePeriod
              label="Monday - Friday"
              name="mondayToFriday"
              startTimeName="mondayToFridayStartTime"
              endTimeName="mondayToFridayEndTime"
            />
            {(isMobile || isTablet) && <Divider className="eiq-divider" />}
          </Col>
          <Col span={getColSize()}>
            <InputTimePeriod label="Saturday" name="saturday" startTimeName="saturdayStartTime" endTimeName="saturdayEndTime" />
            {isMobile && <Divider className="eiq-divider" />}
          </Col>
          <Col span={getColSize()}>
            <InputTimePeriod label="Sunday" name="sunday" startTimeName="sundayStartTime" endTimeName="sundayEndTime" />
            {isMobile && <Divider className="eiq-divider" />}
          </Col>
        </Row>
      </FormWrapper>
    </Card>
  );
});

const mapToFormValues = (data?: IServiceHoursConfiguration | null) => {
  const result: IServiceHoursConfigurationForm = {
    mondayToFridayStartTime: stringToTimeOnly(data?.mondayToFridayStartTime),
    mondayToFridayEndTime: stringToTimeOnly(data?.mondayToFridayEndTime),
    saturdayStartTime: stringToTimeOnly(data?.saturdayStartTime),
    saturdayEndTime: stringToTimeOnly(data?.saturdayEndTime),
    sundayStartTime: stringToTimeOnly(data?.sundayStartTime),
    sundayEndTime: stringToTimeOnly(data?.sundayEndTime),
  };
  return result;
};

const mapToRequest = (model: any) => {
  const result: IServiceHoursConfigurationUpdate = {
    mondayToFridayPeriod: {
      startTime: timeOnlyToString(model.mondayToFridayStartTime),
      endTime: timeOnlyToString(model.mondayToFridayEndTime),
    },
    saturdayPeriod: {
      startTime: timeOnlyToString(model.saturdayStartTime),
      endTime: timeOnlyToString(model.saturdayEndTime),
    },
    sundayPeriod: {
      startTime: timeOnlyToString(model.sundayStartTime),
      endTime: timeOnlyToString(model.sundayEndTime),
    },
  };
  return result;
};
