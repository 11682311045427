import './contact-phones.scss';
import React from 'react';
import { ContactPhonesProps } from './contact-phones.types';
import EiqPhone from '../info-block/contacts/phone/phone-field';

export const ContactPhones = ({ phones, label }: ContactPhonesProps) => {
  return (
    <div className="contact-phones">
      <h2 className="contact-phone__title">{label}</h2>
      {phones.map((phone, index) => {
        const labels = [];

        if (phone.residentName) {
          labels.push({ value: phone.residentName, class: '' });
        }

        labels.push({ value: phone.label, class: phone.label.toLocaleLowerCase() });

        return <EiqPhone phone={phone.number} labels={labels} key={`notification-phone-${index}`} order={phone.order + 1} />;
      })}
    </div>
  );
};
