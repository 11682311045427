import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Col, Row, Button } from 'antd';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import exportService from 'common/services/export-service/export.service';
import { downloadCsv } from 'common/helpers/downloadCsv.helper';

export const ExportData: FC = observer(() => {
  const { isMobile, isTablet } = useMedia();

  const getColSize = () => {
    if (isMobile) {
      return 24;
    }
    if (isTablet) {
      return 12;
    }
    return 8;
  };

  return (
    <Card className="eiq-card eiq-card-border eiq-configuration">
      <Row className="eiq-card-header">
        <Col span={24}>
          <h1 className="h1">Export Residents</h1>
        </Col>
      </Row>
      <Row className="eiq-card-header">
        <Col span={getColSize()}>
          <Button
            type="primary"
            size="middle"
            onClick={async () => {
              const blob = await exportService.processResidents();
              downloadCsv(blob, 'residents-export.csv');
            }}
            className="mobile-btn">
            Export residents
          </Button>
        </Col>
      </Row>
      <Row className="eiq-card-header">
        <Col span={getColSize()}>
          <Button
            type="primary"
            size="middle"
            onClick={async () => {
              const blob = await exportService.processResidentGuests();
              downloadCsv(blob, 'residents-guests-export.csv');
            }}
            className="mobile-btn">
            Export residents with guests
          </Button>
        </Col>
      </Row>
      <Row className="eiq-card-header">
        <Col span={getColSize()}>
          <Button
            type="primary"
            size="middle"
            onClick={async () => {
              const blob = await exportService.processResidentVehicles();
              downloadCsv(blob, 'residents-vehicles-export.csv');
            }}
            className="mobile-btn">
            Export residents with vehicles
          </Button>
        </Col>
      </Row>
    </Card>
  );
});
