import { IResidentAddressRead } from '../../common/services/residents-service/residents.service.types';
import { ICellRendererProps } from '../../common/components/table/table.types';
import BaseCellText from '../../common/components/table/base-cell-text';
import { formatAddressLabel } from '../../common/helpers/address.helper';
import { residentAddressesColumns } from '../../common/list-configs/resident-addresses.config';
import { IAddress } from '../../common/services/street-service/street.service.types';
import { vehiclesColumns } from 'common/list-configs/vehicles.config';
import { IVehicleAdminRead } from 'common/services/vehicle-service/vehicle.service.types';

const { firstName, lastName, phones, pin, type, clubId, streetName, streetNumber } = residentAddressesColumns();
const { owner, ownershipType, year, make, model, color, rantingDates, registrationAddress, status, transponderId } = vehiclesColumns();

export const eiqSecureVehiclesInitialColumns = [
  {
    key: 'resident',
    title: 'Resident Name',
    dataKey: 'resident',
    width: 170,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return `${cell.rowData.resident.firstName} ${cell.rowData.resident.lastName}`;
    },
  },
  {
    key: 'address',
    title: 'Address',
    dataKey: 'address',
    width: 340,
    minWidth: 300,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return formatAddressLabel(cell.rowData.address);
    },
  },
  owner,
  ownershipType,
  transponderId,
  {
    key: 'license',
    title: 'License plate & State',
    dataKey: 'license',
    width: 240,
    minWidth: 220,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IVehicleAdminRead>) => {
      return `${cell.rowData.licenseNumber} (${cell.rowData.licenseState})`;
    },
  },
  year,
  { ...make, width: 100, minWidth: 100 },
  { ...model, width: 100, minWidth: 100 },
  { ...color, width: 100, minWidth: 100 },
  rantingDates,
  { ...registrationAddress, hidden: false },
  status,
];

export const eiqSecureResidentInitialColumns = [
  lastName,
  firstName,
  type,
  streetNumber,
  streetName,
  {
    key: 'addressLabel',
    title: 'Address details',
    dataKey: 'address',
    minWidth: 110,
    width: 350,
    resizable: true,
    sortable: false,
    cellRenderer: ({ cellData }: any) => <BaseCellText>{formatAddressLabel(cellData, { hideStreet: true })}</BaseCellText>,
  },
  phones,
  pin,
  {
    key: 'address_street_community_name',
    title: 'Community',
    dataKey: 'address',
    width: 200,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IResidentAddressRead>) => (
      <BaseCellText>{cell.rowData.address.street.community?.name}</BaseCellText>
    ),
  },
  clubId,
];

export const eiqSecureVacantAddressesInitialColumns = [
  {
    key: 'addressLabel',
    title: 'Address',
    dataKey: 'address',
    minWidth: 110,
    width: 350,
    resizable: true,
    sortable: false,
    cellRenderer: (cell: ICellRendererProps<IAddress>) => <BaseCellText>{formatAddressLabel(cell.rowData)}</BaseCellText>,
  },
  {
    key: 'street_community_name',
    title: 'Community',
    dataKey: 'address',
    width: 200,
    sortable: true,
    resizable: true,
    fixed: 1,
    cellRenderer: (cell: ICellRendererProps<IAddress>) => {
      return <BaseCellText>{cell.rowData.street.community?.name}</BaseCellText>;
    },
  },
];
