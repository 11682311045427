import { Button, Card, Col, Row, Space } from 'antd';
import { ColumnVisibility } from 'common/components/table/filters/column-visibility/column-visibility';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { observer } from 'mobx-react-lite';
import { ReactComponent as RejectIcon } from 'assets/icons/reject.svg';
import React, { useState, useEffect, useRef } from 'react';
import TableBase from 'common/components/table/table-base';
import { citationFilterConfig, citationsInitialColumns } from './citations.config';
import citationService from 'common/services/citation-service/citation.service';
import styles from './citations.module.scss';
import { CitationStatus } from 'features/eiq-manage/enums/citation-status.enum';
import { ReactComponent as ApproveIcon } from 'assets/icons/approve.svg';
import { ICitationRead } from 'common/services/citation-service/citation.service.types';
import { ReactComponent as MoveOut } from 'assets/icons/move-white.svg';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import MoveCitationModal from './move-citation-modal/move-citation-modal';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import CitationPrint from './print-citation/citation-print';
import EditCitationModal from './edit-citation-modal/edit-citation-modal';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { notification } from 'common/utils/notification';
import { ColumnFilter } from 'common/components/table/filters/column-filter/column-filter';
import {
  filterColumnsByPersonalization,
  getTablePersonalizationColumnsData,
  getTablePersonalizationSortData,
  personalizationDefaultTaleSort,
} from 'common/components/table/table-helpers';
import userPersonalizationStore from 'common/stores/user-personalization/user-personalization.store';
import exportService from 'common/services/export-service/export.service';
import { downloadCsv } from 'common/helpers/downloadCsv.helper';
import featureStore from 'common/stores/features/feature.store';

const TABLE_NAME = 'citations';

export const Citations = observer(() => {
  const [isApproveButtonDisabled, setApproveButtonDisabled] = useState(false);
  const [isCancelButtonDisabled, setCancelButtonDisables] = useState(false);

  let initColumns = citationsInitialColumns;
  if (featureStore.isEIQViolationEnabled) {
    initColumns = initColumns.filter(
      (i) => i.key !== 'communityPaymentAmount' && i.key !== 'commissionAmount' && i.key !== 'totalPaymentAmount',
    );
  }
  if (featureStore.isEIQIntelliTicketEnabled) {
    initColumns = initColumns.filter((i) => i.key !== 'currentSuspension' && i.key !== 'citationSuspension');
  }

  const tableControls = useTableControls({
    onFetch: citationService.getCitations,
    initialColumns: filterColumnsByPersonalization(initColumns, TABLE_NAME),
    defaultSort: personalizationDefaultTaleSort(TABLE_NAME),
    onUpdateColumns: async (columns: any[], sortBy: any) => {
      await userPersonalizationStore.updateTableColumnsPersonalization({
        tableName: TABLE_NAME,
        tableColumns: getTablePersonalizationColumnsData(columns),
        tableSort: getTablePersonalizationSortData(sortBy),
      });
    },
  });

  const getRowClassName = (rowIndex: number, rowData: any): string => {
    return rowData?.residentId ? '' : styles.missingTrafficLogRow;
  };

  const onCancelClick = () => {
    const selectedRow = tableControls.getSelectedRow();
    citationService.cancel(selectedRow.id).then(() => {
      if (
        selectedRow.status === CitationStatus.PayNow ||
        selectedRow.status === CitationStatus.PastDue ||
        selectedRow.status === CitationStatus.Delinquent
      ) {
        notification.success({ message: 'Citation and the related suspension is cancelled.', duration: 5 });
      }
      tableControls.updateSelectedRow({ ...selectedRow, status: CitationStatus.Canceled });
    });
  };

  const onApproveClick = () => {
    const selectedRow = tableControls.getSelectedRow();
    citationService.approve(selectedRow.id).then(() => {
      tableControls.updateSelectedRow({ ...selectedRow, status: CitationStatus.PayNow });
    });
  };

  useEffect(() => {
    const row: ICitationRead = tableControls.getSelectedRow();

    if (!row?.residentName) {
      setApproveButtonDisabled(true);
      setCancelButtonDisables(true);
    } else {
      const isCancelDisables = row.status === CitationStatus.Canceled || row.status === CitationStatus.Released;
      setCancelButtonDisables(isCancelDisables);

      const isApproveDisabled = row.status !== CitationStatus.Pending;
      setApproveButtonDisabled(isApproveDisabled);
    }
  }, [tableControls.getSelectedRow()]);

  const moveCitationModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: ICitationRead = tableControls.getSelectedRow();

      if (!selectedRow) {
        return;
      }
      const data = {
        citationId: selectedRow.id,
      };
      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        tableControls.refetchData();
        notification.success({ message: 'Citation was successfully moved.', duration: 5 });
      }
    },
  });

  const editCitationModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: ICitationRead = tableControls.getSelectedRow();

      if (!selectedRow) {
        return;
      }
      const data = {
        citationId: selectedRow.id,
        licenseNumber: selectedRow.licensePlate,
        licenseState: selectedRow.licenseState,
        ownerType: selectedRow.ownerType,
      };
      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        tableControls.refetchData();
        notification.success({ message: 'Citation was successfully updated.', duration: 5 });
      }
    },
  });

  const printRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <>
      {tableControls.getSelectedRow() && <CitationPrint ref={printRef} citation={tableControls.getSelectedRow()} />}
      <TableContextProvider controls={tableControls}>
        <Card className="eiq-card eiq-card-border full-card">
          <Row className="eiq-card-header">
            <Col span={24}>
              <h1 className="h1">Citations</h1>
            </Col>
          </Row>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                placeholder="Search by resident name, license plate, guest name, citation #"
                rulesForColumn={{
                  licensePlate: FilterTypesEnum.Contains,
                  guestName: FilterTypesEnum.Contains,
                  residentName: FilterTypesEnum.Contains,
                  externalId: FilterTypesEnum.Contains,
                }}
              />
            </Col>
            <Col>
              <Space size={8}>
                <Button
                  type="primary"
                  size="middle"
                  onClick={async () => {
                    const blob = await exportService.processCitations(null, tableControls.getFilters(), tableControls.getSortBy());
                    downloadCsv(blob, 'citations.csv');
                  }}
                  className="mobile-btn">
                  Export
                </Button>
                <Button
                  type="default"
                  icon={<PrintIcon />}
                  size={'middle'}
                  onClick={handlePrint}
                  className="mobile-btn"
                  disabled={!tableControls.getSelectedRow()}>
                  Print
                </Button>
                <Button
                  type="default"
                  icon={<EditIcon />}
                  className="mobile-btn"
                  size="middle"
                  onClick={editCitationModal.showModal}
                  disabled={tableControls.getSelectedRow()?.residentId}>
                  Edit
                </Button>
                <Button
                  type="primary"
                  className={styles.approveButton}
                  size="middle"
                  icon={<MoveOut />}
                  onClick={moveCitationModal.showModal}
                  disabled={
                    (!tableControls.getSelectedRow()?.guestName && !tableControls.getSelectedRow()?.companyName) ||
                    tableControls.getSelectedRow()?.status === CitationStatus.Released ||
                    tableControls.getSelectedRow()?.status === CitationStatus.Canceled
                  }>
                  Move
                </Button>
                <Button
                  icon={<ApproveIcon />}
                  size="middle"
                  type="primary"
                  onClick={onApproveClick}
                  disabled={isApproveButtonDisabled}
                  className={styles.approveButton}>
                  Approve
                </Button>
                <Button
                  icon={<RejectIcon />}
                  size="middle"
                  type="primary"
                  onClick={onCancelClick}
                  danger
                  disabled={isCancelButtonDisabled}
                  className={styles.cancelButton}>
                  Cancel
                </Button>
                <ColumnVisibility isColumnReorderAllow={true} />
                <ColumnFilter filterConfig={citationFilterConfig} />
              </Space>
            </Col>
          </Row>
          <TableBase onSelected={tableControls.setSelectedRow} selectFirstRowByDefault rowClassName={getRowClassName} />
        </Card>
      </TableContextProvider>
      <MoveCitationModal
        initData={moveCitationModal?.initData}
        title="Move citation"
        isOpen={moveCitationModal?.isOpenModal}
        onClose={moveCitationModal?.hideModal}
      />
      <EditCitationModal
        initData={editCitationModal?.initData}
        title="Edit License Plate Number and Type"
        isOpen={editCitationModal?.isOpenModal}
        onClose={editCitationModal?.hideModal}
      />
    </>
  );
});
