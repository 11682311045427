import { ICellRendererProps } from 'common/components/table/table.types';
import {
  ICameraConfiguration,
  IDefaultConfiguration,
  IFacilityCodeResponse,
  IGateConfiguration,
} from 'common/services/configuration-service/configuration.service.types';

export const gatesTableInitialColumns = [
  {
    key: 'name',
    title: 'Name',
    dataKey: 'name',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  {
    key: 'longitude',
    title: 'Longitude',
    dataKey: 'longitude',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
  },
  {
    key: 'latitude',
    title: 'Latitude',
    dataKey: 'latitude',
    width: 150,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
  },
  {
    key: 'cameras',
    title: 'Cameras',
    dataKey: 'cameras',
    width: 800,
    minWidth: 400,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IGateConfiguration>) => {
      var result = cell.cellData?.map((i: ICameraConfiguration) => `${i.name} (${i.direction})`);
      return result?.join(', ');
    },
  },
];

export const cardClassesTableInitialColumns = [
  {
    key: 'name',
    title: 'Name',
    dataKey: 'name',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  {
    key: 'value',
    title: 'Number',
    dataKey: 'value',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
  },
];

export const facilityCodesTableInitialColumns = [
  {
    key: 'value',
    title: 'EIQ Facility Code',
    dataKey: 'value',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: true,
    flexGrow: 1,
  },
  {
    key: 'cardAccessSystemValue',
    title: 'Slot',
    dataKey: 'cardAccessSystemValue',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
  },
  {
    key: 'isSyncToCardAccessSystem',
    title: 'Sync to CA3K',
    dataKey: 'isSyncToCardAccessSystem',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
    cellRenderer: (cell: ICellRendererProps<IFacilityCodeResponse>) => {
      return cell.cellData ? 'Yes' : 'No';
    },
  },
  {
    key: 'defaultForType',
    title: 'Used by default for',
    dataKey: 'defaultForType',
    width: 200,
    minWidth: 150,
    resizable: true,
    sortable: false,
    flexGrow: 1,
  },
];
