import residentStore from 'features/eiq-manage/stores/resident.store';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { residentAccessCardColumns } from './access-card-list.comfig';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import { Button, Col, Row, Space } from 'antd';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import TableBase from 'common/components/table/table-base';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import AddEditAccessCardModal from './add-edit-access-card-modal/add-edit-access-card-modal';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import configurationStore from 'common/stores/configuration/configuration.store';
import { IAddEditAccessCardModal } from './access-card-list.types';
import { IAccessCardRead } from 'common/services/residents-service/residents.service.types';
import ConfirmDeleteModal from 'common/components/modal/confirm-delete-modal/confirm-delete-modal';
import accessCardService from 'common/services/access-card-service/access-card.service';
import { IConfirmDeleteModalProps } from 'common/components/modal/confirm-delete-modal/confirm-delete-modal.types';
import dayjs from 'dayjs';
import featureStore from 'common/stores/features/feature.store';

export const AccessCardList = observer(() => {
  let columns = residentAccessCardColumns;

  if (!featureStore.isCA3000ExpirationsEnabled) {
    columns = columns.filter((i) => i.key !== 'validityPeriod');
  }
  if (!featureStore.isCA3000CardClassesEnabled) {
    columns = columns.filter((i) => i.key !== 'cardClass');
  }

  const tableControls = useTableControls({
    clientSide: true,
    data: residentStore.accessCard,
    initialColumns: columns,
  });

  const addAccessCardModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const residentId = residentStore.residentId;
      const addressId = residentStore.currentAddress?.address.id;

      if (!addressId || !residentId) {
        console.error(`Resident is not selected`);
        return;
      }

      const defaultFacilityCode = configurationStore.defaultForAccessCard;

      const initData: IAddEditAccessCardModal = {
        facilityCode: defaultFacilityCode ?? null,
        deviceId: null,
        registrationAddress: null,
        additionalInformation: null,
        owner: null,
        validFrom: null,
        validTo: null,
        residentId: residentId,
        addressId: addressId,
        isDeactivated: false,
        deactivationReason: null,
        cardClass: null,
      };

      setModalInitData(initData);
    },
    onClose: (isSuccess, accessCard: IAccessCardRead) => {
      if (isSuccess) {
        residentStore.addAccessCard(accessCard);
      }
    },
  });

  const editVehicleModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow: IAccessCardRead = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      const residentId = residentStore.residentId;
      const addressId = residentStore.currentAddress?.address.id;

      if (!addressId || !residentId) {
        console.error(`Resident is not selected`);
        return;
      }

      const editAccessCardModel: IAddEditAccessCardModal = {
        id: selectedRow.id,
        facilityCode: selectedRow.facilityCode,
        deviceId: selectedRow.deviceId,
        registrationAddress: selectedRow.registrationAddress,
        additionalInformation: selectedRow.additionalInformation,
        validFrom: selectedRow.validFrom ? dayjs(selectedRow.validFrom) : null,
        validTo: selectedRow.validTo ? dayjs(selectedRow.validTo) : null,
        owner: selectedRow.owner,
        residentId: residentId,
        addressId: addressId,
        isDeactivated: selectedRow.isDeactivated,
        deactivationReason: selectedRow.deactivationReason,
        cardClass: selectedRow.cardClass,
      };

      setModalInitData(editAccessCardModel);
    },
    onClose: (isSuccess, accessRead: IAccessCardRead) => {
      if (isSuccess) {
        residentStore.updateAccessCard(accessRead);
        tableControls.updateSelectedRow(accessRead);
      }
    },
  });

  const deleteAccessCardModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const selectedRow = tableControls.getSelectedRow();
      if (!selectedRow) {
        return;
      }

      const residentId = residentStore.residentId;
      const addressId = residentStore.currentAddress?.address.id;

      if (!addressId || !residentId) {
        console.error(`Resident is not selected`);
        return;
      }

      const data: IConfirmDeleteModalProps = {
        message: 'Are you sure you want to delete this device?',
        onDelete: () => {
          accessCardService.delete(residentId, addressId, selectedRow.id).then((result) => {
            deleteAccessCardModal.hideModal(true);
          });
        },
      };

      setModalInitData(data);
    },
    onClose: (isSuccess: boolean | undefined) => {
      if (isSuccess) {
        const selectedRow: IAccessCardRead = tableControls.getSelectedRow();
        residentStore.deleteAccessCard(selectedRow.id);
        tableControls.setSelectedRow(null);
      }
    },
  });

  return (
    <>
      <TableContextProvider controls={tableControls}>
        <div>
          <Row gutter={8} align="middle" style={{ marginBottom: '16px' }}>
            <Col flex="auto">
              <SearchFilter
                adaptiveSearch={true}
                enableProgressiveSearch={true}
                placeholder="Search by owner, facility code, device ID, address of registration"
                rulesForColumn={{
                  owner: FilterTypesEnum.Contains,
                  facilityCode: FilterTypesEnum.Contains,
                  deviceId: FilterTypesEnum.Contains,
                  registrationAddress: FilterTypesEnum.Contains,
                }}
                rightSide={
                  <Space size={8}>
                    <Button
                      type="default"
                      icon={<DeleteIcon stroke="#505762" />}
                      size="middle"
                      className="mobile-btn"
                      onClick={deleteAccessCardModal.showModal}
                      disabled={!tableControls.getSelectedRow()}>
                      Delete
                    </Button>
                    <Button
                      type="default"
                      icon={<EditIcon />}
                      size="middle"
                      className="mobile-btn"
                      onClick={editVehicleModal.showModal}
                      disabled={!tableControls.getSelectedRow()}>
                      Edit
                    </Button>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      size="middle"
                      onClick={addAccessCardModal.showModal}
                      className="mobile-btn">
                      Add
                    </Button>
                  </Space>
                }
              />
            </Col>
          </Row>
          <TableBase selectFirstRowByDefault />
        </div>
      </TableContextProvider>
      <AddEditAccessCardModal
        isEdit={true}
        initData={editVehicleModal?.initData}
        title="Edit"
        isOpen={editVehicleModal?.isOpenModal}
        onClose={editVehicleModal?.hideModal}
      />
      <AddEditAccessCardModal
        isEdit={false}
        initData={addAccessCardModal?.initData}
        title="Add"
        isOpen={addAccessCardModal?.isOpenModal}
        onClose={addAccessCardModal?.hideModal}
      />
      <ConfirmDeleteModal
        isEdit={false}
        initData={deleteAccessCardModal?.initData}
        title="Delete"
        isOpen={deleteAccessCardModal?.isOpenModal}
        onClose={deleteAccessCardModal?.hideModal}
      />
    </>
  );
});
