import React from 'react';
import { petsColumns } from 'common/list-configs/pets.config';
import { vehiclesColumns } from 'common/list-configs/vehicles.config';
import { occupantsColumns } from 'common/list-configs/occupants.config';
import { occupantsEIQManageColumns } from '../../../../../list-config/occupants.config';
import { guestColumns } from 'common/list-configs/guest-list.config';

const { lastName, firstName, companyName, validity, daysOfWeek, dateGuestAdded } = guestColumns();
export const guestPrintColumns = [
  { ...lastName, width: 140 },
  { ...firstName, width: 140 },
  { ...companyName, width: 150 },
  validity,
  daysOfWeek,
  { ...dateGuestAdded, width: 130 },
];

const { owner, make, model, licenseNumber, licenseState, color, year, deviceId } = vehiclesColumns();
export const vehiclePrintColumns = [
  owner,
  { ...year, width: 70 },
  { ...make, width: 130 },
  { ...model, width: 130 },
  { ...color, width: 100 },
  licenseNumber,
  licenseState,
  deviceId,
];

const petsPrintColumns = () => {
  const { name, type, breed, chipNumber, color, photo, tag } = petsColumns();
  const columns = [
    {
      ...photo,
      width: 215,
      height: 215,
      cellRenderer: (cell: any) => {
        return <img src={cell.cellData} width="100%" height="auto" alt="" />;
      },
    },
    name,
    { ...type, width: 125 },
    breed,
    { ...color, width: 125 },
    { ...chipNumber, width: 125 },
    { ...tag, width: 125 },
  ];

  return columns;
};
export const petPrintColumns = [...petsPrintColumns()];

const occupantsPrintColumns = () => {
  const { name, ownershipType, hostName, hostOwnershipType } = occupantsColumns();
  const { isSharedGuestList, isSharedVehiclesList } = occupantsEIQManageColumns();

  const columns = [name, ownershipType, hostName, hostOwnershipType, isSharedGuestList, isSharedVehiclesList];

  columns.forEach((i) => (i.width = 200));

  return columns;
};
export const occupantPrintColumns = [...occupantsPrintColumns()];
