import { Button, Dropdown, Form, Input, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FormWrapper } from '../../../form-wrapper/form-wrapper';
import { useTableContext } from '../../table-context';
import './column-filter.scss';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { FilterTypesEnum } from '../../../../enums/filter-types.enum';
import _ from 'lodash';
import { DatePicker, Radio } from 'antd';
import { notification } from '../../../../utils/notification';
import { DATE_FORMAT_MMMM_D_YYYY } from '../../../../constans/date.constants';
import { ColumnFilterProps, IDateRange, IFilterConfig, IFilterSelect, IFilterSelectAsync, INumberRange } from './column-filter.types';

export const ColumnFilter = ({
  filterConfig,
  buttonText,
  buttonIcon,
  buttonType,
  width,
  onFilterChanged,
  onBeforeApply,
  onResetFilters,
  classNameContent,
}: ColumnFilterProps) => {
  const tableContext = useTableContext();
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [selectedRadio, setSelectedRadio] = useState<number | null>(null);
  const [currentFilterConfig, setCurrentFilterConfig] = useState<IFilterConfig>(filterConfig[0]);
  const [filterButtonClassName, setFilterButtonClassName] = useState('');

  useEffect(() => {
    if (onFilterChanged) {
      onFilterChanged(tableContext);
    }
  }, [selectedRadio]);

  const onFinish = (values: any) => {
    if (selectedRadio !== null) {
      if (buttonText !== '') {
        setFilterButtonClassName('active-filter');
      } else {
        setFilterButtonClassName('active-filter-no-text');
      }
      if (onBeforeApply) {
        onBeforeApply(tableContext);
      }

      const getFilterValue = (config: IFilterConfig, values: any): any => {
        const dateType = config?.type as IDateRange;
        if (dateType?.type === 'DATE_RANGE') {
          const dateStart = values[filterConf.name]?.format('YYYY-MM-DD');
          const dateEnd = values[dateType.nameEnd + '2']?.format('YYYY-MM-DD');

          return { start: dateStart, end: dateEnd };
        } else if (dateType?.type === 'NUMBER_RANGE') {
          const dateStart = values[filterConf.name];
          const dateEnd = values[dateType.nameEnd + '2'];

          return { start: dateStart, end: dateEnd };
        } else {
          return values[filterConf.name];
        }
      };

      const filterConf: IFilterConfig = filterConfig[selectedRadio as number] as IFilterConfig;
      const filterValue = getFilterValue(filterConf, values);
      const selectType = filterConf?.type as IFilterSelect;

      const dateType = filterConf?.type as IDateRange;
      const rangeType = filterConf?.type as INumberRange;

      if (dateType?.type === 'DATE_RANGE') {
      }

      if (filterConf?.onChange) {
        filterConf.onChange(filterValue, tableContext);
      } else if (selectType?.onChange) {
        selectType.onChange(filterValue, tableContext);
      } else if (dateType?.type === 'DATE_RANGE') {
        const endName = dateType.nameEnd;
        const dateStart = filterValue?.start;
        const dateEnd = filterValue?.end;

        if (filterConf.name === endName) {
          const firstCondition =
            dateStart !== null && dateStart !== undefined ? { operator: FilterTypesEnum.GreaterThan, value: dateStart } : null;
          const secondCondition = dateEnd !== null && dateEnd !== undefined ? { operator: FilterTypesEnum.LessThan, value: dateEnd } : null;

          tableContext.setFilter(filterConf.name, firstCondition && secondCondition ? [firstCondition, secondCondition] : null, 'AND');
        } else {
          tableContext.setFilter(
            filterConf.name,
            dateStart !== null && dateStart !== undefined ? { operator: FilterTypesEnum.GreaterThan, value: dateStart } : null,
            'AND',
          );
          tableContext.updateFilter(
            endName,
            dateEnd !== null && dateEnd !== undefined ? { operator: FilterTypesEnum.LessThan, value: dateEnd } : null,
            'AND',
          );
        }
      } else if (rangeType?.type === 'NUMBER_RANGE') {
        const endName = rangeType.nameEnd;
        const from = filterValue?.start;
        const to = filterValue?.end;

        if (filterConf.name === endName) {
          const firstCondition = from !== null && from !== undefined ? { operator: FilterTypesEnum.GreaterThan, value: from } : null;
          const secondCondition = to !== null && to !== undefined ? { operator: FilterTypesEnum.LessThan, value: to } : null;

          tableContext.setFilter(filterConf.name, firstCondition && secondCondition ? [firstCondition, secondCondition] : null, 'AND');
        } else {
          tableContext.setFilter(
            filterConf.name,
            from !== null && from !== undefined ? { operator: FilterTypesEnum.GreaterThan, value: from } : null,
            'AND',
          );
          tableContext.updateFilter(
            endName,
            to !== null && to !== undefined ? { operator: FilterTypesEnum.LessThan, value: to } : null,
            'AND',
          );
        }
      } else if (selectType?.mode === 'multiple') {
        const conditions = filterValue.map((i: any) => ({
          operator: currentFilterConfig.filterType ?? FilterTypesEnum.Contains,
          value: i,
        }));

        tableContext.setFilter(
          filterConf.name,
          filterValue !== null && filterValue !== undefined && (filterValue?.length > 0 || typeof filterValue === 'number')
            ? conditions
            : null,
          'AND',
        );
      } else {
        tableContext.setFilter(
          filterConf.name,
          filterValue !== null && filterValue !== undefined && (filterValue?.length > 0 || typeof filterValue === 'number')
            ? { operator: currentFilterConfig.filterType ?? FilterTypesEnum.Contains, value: filterValue }
            : null,
          'AND',
        );
      }
    }
    setOpen(false);
  };

  const resetFilter = () => {
    form.resetFields();
    setSelectedRadio(null);
    setFilterButtonClassName('');
    tableContext.resetFilter('AND');
    setOpen(false);
    if (onResetFilters) {
      onResetFilters(tableContext);
    }
  };

  const RadioGroup = ({ children, label, name, index, filterConfig }: any) => {
    return (
      <div className="eiq-filter-item">
        <Radio.Group
          value={selectedRadio}
          onChange={(e) => {
            setSelectedRadio(e.target.value);
            setCurrentFilterConfig(filterConfig);
          }}>
          <Radio value={index}>{label}</Radio>
        </Radio.Group>
        <div className="eiq-filter-content" style={{ display: selectedRadio === index ? 'block' : 'none' }}>
          {children}
        </div>
      </div>
    );
  };

  const residents = useRef<{ [key: string]: any[] }>({});

  // What is it? What do we handle resident logic inside COMMON component?
  const SelectAsyncFilter = ({ optionValue, optionLabel, type, searchField, onSearch, name }: IFilterSelectAsync & { name: string }) => {
    // Don't touch, it is force rerender;
    const [residents2, setResidents2] = useState<any[]>([]);

    const handleResidentSearch = (newValue?: string) => {
      onSearch(newValue ? `${'name'}:*` + newValue : undefined, newValue)
        .then((options) => {
          residents.current = {
            ...residents.current,
            [name]: options.items,
          };
          setResidents2(options.items);
        })
        .catch((e) => {
          residents.current = {
            ...residents.current,
            [name]: [],
          };
          setResidents2([]);
          notification.error({ message: 'Can not fetch street list' });
          console.log(e);
        });
    };

    useEffect(() => {
      if (!residents?.current[name]) {
        handleResidentSearch();
      }
    }, []);

    const options = residents?.current[name]?.map((resid) => ({ value: resid?.[optionValue], label: resid?.[optionLabel] }));

    return (
      <Form.Item name={name}>
        <Select
          showSearch
          allowClear
          defaultActiveFirstOption={false}
          onSearch={handleResidentSearch}
          // onDropdownVisibleChange={handleDropdownVisibleChange}
          // onDropdownVisibleChange={(isOpen) => isOpen && residents?.length === 0 && handleResidentSearch()}
          filterOption={(inputValue, option: any) =>
            option?.[searchField]?.toString()?.toLowerCase()?.includes(inputValue.toLowerCase()) ?? false
          }
          options={options}
        />
      </Form.Item>
    );
  };

  const content = () => (
    <div className={`column-filter ${classNameContent ?? ''}`}>
      <FormWrapper onFinish={onFinish} form={form}>
        <div className="column-visibility__header">
          <span>Filters</span>
          <div>
            <Button onClick={resetFilter} type="link" size={'small'}>
              Reset
            </Button>
            <Button htmlType="submit" type="link" size={'small'}>
              Apply
            </Button>
          </div>
        </div>
        {filterConfig &&
          filterConfig.map((column, index: number) => {
            return (
              <RadioGroup key={index} name={column.name} label={column.label} filterConfig={column} index={index}>
                {column.type === 'INPUT' && <InputFilter name={column.name} label={column.label} />}
                {(column?.type as IFilterSelectAsync)?.type === 'SELECT_ASYNC' && (
                  <SelectAsyncFilter name={column.name} {...(column?.type as IFilterSelectAsync)} />
                )}
                {(column?.type as IFilterSelect)?.type === 'SELECT' && (
                  <SelectFilter name={column.name} {...(column?.type as IFilterSelect)} />
                )}

                {(column?.type as IDateRange)?.type === 'DATE_RANGE' && (
                  <div>
                    <Form.Item name={column.name}>
                      {/*<DatePicker format="MMMM D, YYYY" picker="date" placeholder="Start date" />*/}
                      <DatePicker format={DATE_FORMAT_MMMM_D_YYYY} placeholder="Start date" />
                    </Form.Item>
                    <div className="eiq-filter-to">To</div>
                    <Form.Item name={(column.type as IDateRange).nameEnd + '2'}>
                      <DatePicker format={DATE_FORMAT_MMMM_D_YYYY} placeholder="End date" />
                    </Form.Item>
                  </div>
                )}

                {(column?.type as INumberRange)?.type === 'NUMBER_RANGE' && (
                  <div>
                    <div className="eiq-filter-to">From</div>
                    <Form.Item name={column.name}>
                      <Input type="number" min={1} />
                    </Form.Item>
                    <div className="eiq-filter-to">To</div>
                    <Form.Item name={(column.type as INumberRange).nameEnd + '2'}>
                      <Input type="number" min={1} />
                    </Form.Item>
                  </div>
                )}
              </RadioGroup>
            );
          })}
      </FormWrapper>
    </div>
  );

  return (
    <Dropdown
      overlayStyle={{ width: width ? width + 'px' : 200 }}
      open={open}
      onOpenChange={setOpen}
      dropdownRender={content}
      trigger={['click']}>
      <Button
        type={buttonType ?? 'default'}
        icon={buttonIcon ?? <FilterIcon />}
        className={`mobile-btn ${filterButtonClassName}`}
        size={'middle'}>
        {buttonText ?? 'Filter'}
      </Button>
    </Dropdown>
  );
};

const InputFilter = ({ label, name }: any) => {
  return (
    <Form.Item name={name} noStyle>
      <Input placeholder={`Enter ${_.lowerFirst(label)}`} />
    </Form.Item>
  );
};

const SelectFilter = ({ type, searchField = 'label', options, name, mode }: IFilterSelect & { name: string }) => {
  return (
    <Form.Item name={name}>
      <Select
        mode={mode}
        showSearch
        allowClear
        filterOption={(inputValue, option: any) =>
          option?.[searchField]?.toString()?.toLowerCase()?.includes(inputValue.toLowerCase()) ?? false
        }
        options={options}
      />
    </Form.Item>
  );
};
