import userPersonalizationService from 'common/services/user-personalization-service/user-personalization.service';
import { makeAutoObservable } from 'mobx';
import React from 'react';
import {
  IUserPersonalizationRequest,
  IUserPersonalizationResponse,
} from 'common/services/user-personalization-service/user-personalization.service.types';
import { USER_PERSONALIZATION_DATA } from './user-personalization.constants';
import { debounce } from 'lodash';

class UserPersonalizationStore {
  personalization: IUserPersonalizationResponse | null = null;

  constructor() {
    makeAutoObservable(this);
    this.restoreUserPersonalization();
  }

  setUserPersonalization(personalization: IUserPersonalizationResponse | null) {
    localStorage.setItem(USER_PERSONALIZATION_DATA, JSON.stringify(personalization));
    this.personalization = personalization;
  }

  restoreUserPersonalization() {
    const data = localStorage.getItem(USER_PERSONALIZATION_DATA);
    this.personalization = data ? JSON.parse(data) : null;
  }

  clearUserPersonalization() {
    localStorage.removeItem(USER_PERSONALIZATION_DATA);
    this.personalization = null;
  }

  async requestUserPersonalization() {
    const data = await userPersonalizationService.get();
    this.setUserPersonalization(data);
  }

  updateTableColumnsPersonalization = debounce(async (tablePersonalizationData: IUserPersonalizationRequest) => {
    const data = await userPersonalizationService.updateTableColumnsPersonalization(tablePersonalizationData);
    this.setUserPersonalization(data);
  }, 350);
}

const userPersonalizationStore = new UserPersonalizationStore();
export const UserPersonalizationStoreContext = React.createContext<UserPersonalizationStore>(userPersonalizationStore);
export default userPersonalizationStore;
