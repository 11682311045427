import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { useNotifications } from '../../../../common/context/NotificationsContext';
import NotificationItem from './custom-notification-item/custom-notification-item';
import './plate-scan-activity-panel.scss';

const PlateScanActivityPanel = () => {
  const { notifications, clearNotifications } = useNotifications();
  const [isPanelVisible, setIsPanelVisible] = useState(true);

  const toggleVisibility = () => {
    setIsPanelVisible((prev) => !prev);
  };

  return (
    <div className="plate-scan-activity-panel">
      {notifications.length ? <NotificationItem isLastNotification notification={notifications[0]} /> : null}
      <div className="main-panel">
        <div className="panel-header">
          <h3 className="h3">PlateScan Activity</h3>
          <span className="close-btn" onClick={toggleVisibility}>
            {isPanelVisible ? <UpOutlined /> : <DownOutlined />}
          </span>
        </div>
        {isPanelVisible && (
          <>
            <div className="notifications">
              {notifications.map((item) => (
                <NotificationItem key={item.id} notification={item} />
              ))}
            </div>
            <Space className="traffic-btn">
              <Button type="link" onClick={clearNotifications}>
                <Link to={'/eiq-secure/traffic'}>See all activity in Traffic</Link>
              </Button>
            </Space>
          </>
        )}
      </div>
    </div>
  );
};

export default PlateScanActivityPanel;
