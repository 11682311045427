import { makeAutoObservable } from 'mobx';
import { FEATURES } from './feature.constants';
import React from 'react';
import { IFeature } from 'common/services/feature-service/feature-service.types';
import featureService from 'common/services/feature-service/feature.service';
import { FeatureName } from 'common/enums/feature-name.enum';

class FeatureStore {
  features: IFeature[] = [];

  constructor() {
    makeAutoObservable(this);
    this.restoreFeatures();
  }

  getByName(name: FeatureName) {
    return this.features.find((i) => i.name === name);
  }

  toggleFeature(id: string, isEnabled: boolean) {
    const index = this.features.findIndex((i) => i.id === id);
    if (index !== -1) {
      this.features[index].isEnabled = isEnabled;
      this.setFeatures(this.features);
    }
  }

  setFeatures(features: IFeature[]) {
    localStorage.setItem(FEATURES, JSON.stringify(features));
    this.features = features;
  }

  restoreFeatures() {
    const source = localStorage.getItem(FEATURES);
    this.features = source ? JSON.parse(source) : [];
  }

  clearFeatures() {
    localStorage.removeItem(FEATURES);
    this.features = [];
  }

  async requestFeatures() {
    const features = await featureService.getFeatures();
    this.setFeatures(features);
  }

  IsEnabled(featureName: FeatureName) {
    return !!this.features?.find((feature) => feature.name === featureName)?.isEnabled;
  }

  get isCardAccessEnabled() {
    return this.IsEnabled(FeatureName.CA3000) || this.IsEnabled(FeatureName.CA4K);
  }

  get isTrafficLogixEnabled() {
    return this.IsEnabled(FeatureName.EIQIntelliTicket) || this.IsEnabled(FeatureName.EIQViolation);
  }

  get isEIQIntelliTicketEnabled() {
    return this.IsEnabled(FeatureName.EIQIntelliTicket);
  }

  get isEIQViolationEnabled() {
    return this.IsEnabled(FeatureName.EIQViolation);
  }

  get isReportsEnabled() {
    return this.IsEnabled(FeatureName.Reports);
  }

  get isConnectEnabled() {
    return this.IsEnabled(FeatureName.Connect);
  }

  get isCA3000ExpirationsEnabled() {
    return this.IsEnabled(FeatureName.CA3000) && this.IsEnabled(FeatureName.CA3000Expirations);
  }

  get isCA3000CardClassesEnabled() {
    return this.IsEnabled(FeatureName.CA3000) && this.IsEnabled(FeatureName.CA3000CardClasses);
  }

  get isNorthStar() {
    return this.IsEnabled(FeatureName.NorthStar);
  }

  get isFastAccessEnabled() {
    return this.IsEnabled(FeatureName.FastAccess);
  }

  get isFastAccessAlertsEnabled() {
    return this.IsEnabled(FeatureName.FastAccess) && this.IsEnabled(FeatureName.FastAccessAlerts);
  }

  get isFastAccessRestrictionsEnabled() {
    return this.IsEnabled(FeatureName.FastAccess) && this.IsEnabled(FeatureName.FastAccessRestrictions);
  }

  get isSMSNotificationsEnabled() {
    return this.IsEnabled(FeatureName.SmsNotifications);
  }

  get isMassNotificationsEnabled() {
    return this.IsEnabled(FeatureName.MassNotifications);
  }

  get isSMSMassNotificationsEnabled() {
    return this.IsEnabled(FeatureName.MassNotifications) && this.IsEnabled(FeatureName.SmsMassNotifications);
  }

  get isIncidentReportsEnabled() {
    return this.IsEnabled(FeatureName.IncidentReports);
  }

  get isPlateScanEnabled() {
    return this.IsEnabled(FeatureName.LPR);
  }

  get isRealTimeNotificationsEnabled() {
    return this.IsEnabled(FeatureName.LPR) || this.IsEnabled(FeatureName.ParkFlow);
  }

  get isGuestLimitationsEnabled() {
    return this.IsEnabled(FeatureName.GuestLimitations);
  }
}

const featureStore = new FeatureStore();
export const FeatureStoreContext = React.createContext<FeatureStore>(featureStore);
export default featureStore;
