import { observer } from 'mobx-react-lite';
import { Card, Col, Row } from 'antd';
import React from 'react';
import configurationStore from 'common/stores/configuration/configuration.store';
import { ProtectText } from 'common/components/protect-text/protect-text';
import '../community-information.scss';

export const VerificationConfiguration = observer(() => {
  const getCode = () => {
    return configurationStore.communitySettings?.verificationBackupCode ? (
      <ProtectText text={configurationStore.communitySettings.verificationBackupCode} className="alarm-code" />
    ) : (
      '-'
    );
  };

  return (
    <Card className="eiq-card eiq-card-border eiq-configuration">
      <Row className="eiq-card-header">
        <Col span={24}>
          <h1 className="h1">EIQ-Voice</h1>
        </Col>
      </Row>
      <Row>
        <div className="community-information__row-info">
          <span>Verification backup code:</span> {getCode()}
        </div>
      </Row>
    </Card>
  );
});
